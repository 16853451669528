
@media(max-width:768px) {
    .custome-style {
        flex-direction: column;
        gap: 3em;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:640px){
    .custome_tag{
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
    }
    .custome-border{
        border-bottom: 1px solid white;
    }

    .custome-mobile-style{
        text-align: center;
        margin-bottom: 30px;
    }
}

.bg-color-custom{
    background-color: #271777 !important;
}