.marquee-container {
    padding: 3rem 0;
  }
  
  @media screen and (max-width: 992px) {
    .marquee-container {
      padding: 1rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .backer-logo {
      width: 135px;
      height: 135px;
      margin: 1.2rem;
      padding: 2rem 1rem;
    }
  }
  .social1-h{
    color: #FFF;
text-align: center;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 48px */
letter-spacing: -0.64px;
  }
  