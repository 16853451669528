.access-main{
    background-color: #5741EE;
}
.access-h{
    color:  #FFF;
text-align: center;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 141%; /* 56.4px */
letter-spacing: -1.2px;
}
.access-p{
    color: #DBDEE4;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 30px */
letter-spacing: -0.4px;
}
.access-btn{
    border-radius: 30px;
background: linear-gradient(90deg, rgba(4, 237, 243, 0.72) 0%, #5741EE 100%);
}