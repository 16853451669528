.world-h{
    color: #FFF;
text-align: center;
font-family: Manrope;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 48px */
letter-spacing: -0.64px;
text-transform: uppercase;
}
.world-p{
    color: #FFF;
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: 112%; /* 32.48px */
letter-spacing: -0.58px;
}