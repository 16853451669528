.hero-text{
    color: #FFF;
font-family: Manrope;
font-size: 68px;
font-style: normal;
font-weight: 800;
line-height: 101%; /* 68.68px */
letter-spacing: -1.36px;
text-transform: uppercase;
}
.hero-text2{
    color: #FFF;
font-family: Manrope;
font-size: 59px;
font-style: normal;
font-weight: 400;
line-height: 90%; /* 53.1px */
letter-spacing: -1.18px;
}