.de-heading{
    color: #57FFFF;
    font-family: 'Silkscreen', cursive !important;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -1.8px;
}
.de-para{
    color: #FFF;
font-family: Manrope;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 150%;
letter-spacing: -0.64px;
}
.decentra-h{
    color: #FFF;
font-size: 31px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 48px */
letter-spacing: -0.64px;
text-transform: uppercase;
}
.decentra-p{
    color: #FFF;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 112%; /* 26.88px */
letter-spacing: -0.48px;
}