
.custom-radius{
    border-radius: 45px;
}

@media(max-width:640px){
    .custom-radius{
        border-radius: 80px;
    }
    .custom-width{
        width: 100%;
    }
}

.bg-about-us{
    background-image: url('../../assets/Background/BG.png');
    font-family: Manrope !important;
}

.custom-text-color{
    color: #20208A;
}