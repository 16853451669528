.counter{
    border-radius: 16px;
background: linear-gradient(358deg, rgba(0, 34, 178, 0.38) -29.28%, rgba(57, 72, 227, 0.20) 141.58%);
}
.counter-text{
    color: #57FFFF;
font-family: Manrope;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 48px */
letter-spacing: -0.96px;
}
.counter-p{
    color: #FFF;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: -0.6px;
}